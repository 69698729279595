import React, { useState, useEffect } from 'react';

function About() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div name='about' className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white'>
      {windowWidth <= 550 && (
        <>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      <div className='max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
            </div>
            <p className='text-xl mt-10'>
            Currently in my third year at the University of Western Ontario's Software Engineering program. My education has been a mix of challenging coursework and practical projects. A standout experience was winning the Western Engineering Competition, where my team developed a Python-based carbon unit converter. This achievement highlights my skill in creating and executing complex solutions. Beyond the classroom, my focus is on practical applications of technology, aiming to create meaningful and effective software solutions.
            </p>
            <br />
            <p className='text-xl'>
                I'm currently serving as the VP of Communications at Western Crypto Club as well as the Microcontroller and Circuitry Team Lead at Western Chem E Car team. Through these postions I've honed my ability to manage strategic communications and lead technical teams. These roles have developed my project management skills and ability to drive projects to successful completion. My passion for technology and innovation drives me, and I am keen to bring my unique blend of creativity, leadership, and technical insight to the evolving tech environment.
            </p>
      </div>
    </div>
  )
}

export default About