import React from 'react';
import HeroImage from '../assets/heroImage.png'
import {FaPersonWalkingArrowRight} from 'react-icons/fa6';
import { Link } from 'react-scroll'

function Home_1() {
  return (
    <div name="home" className='h-screen w-full bg-gradient-to-b from-black via-black to-gray-800'>
        <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 sm:flex-row'>
            <div className='flex flex-col justify-center h-full'>
                <h2 className='text-2xl sm:text-7xl font-bold text-white pt-16'>Hello! I'm Aaqil, a Software Engineer.</h2>
                <p className='text-gray-500 py-4 max-w-md'>
                I have 3+ years in the tech industry and currently I'm in my third year of Software Engineering at the University of Western Ontario. See below for my projects that include immersive gaming applications and practical software tools.
                </p>
                <div>
                <Link to="portfolio" smooth duration={500} className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer'>
                    Portfolio   
                    <span className="ml-3 group-hover:rotate-90 duration-300">
                        <FaPersonWalkingArrowRight size={20}/>
                    </span>
                </Link>
            </div>
            </div>

            <div>
                <img src={HeroImage} alt="My profile" className='rounded-2xl mx-auto w-2/3 md:w-full'/>
            </div>
        </div>
    </div>
  )
}

export default Home_1